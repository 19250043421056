import { Box, Button } from "components";
import { Path } from "navigations/routes";
import React, { useId, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getActiveLink } from "utils/getActiveLink";
import SideNavLink from "../sideNavLink";
import { MdSpaceDashboard, MdSettings } from "react-icons/md";
import { HiDocumentReport } from "react-icons/hi";
import { AiFillBank, AiFillNotification } from "react-icons/ai";
import { RiInboxArchiveFill } from "react-icons/ri";
import { BsBag, BsBagFill, BsFillChatSquareDotsFill } from "react-icons/bs";
import { BiLogOut, BiMoney, BiSend, BiUser } from "react-icons/bi";
import "./sideNav.scss";
import { ActionTypes, useAuth } from "contexts/AuthContext";
import { clearAccessToken } from "utils/localStorage";

const SideNav = () => {
  const links = [
    {
      text: "Dashboard",
      link: Path.DASHBOARD,
      id: useId(),
      icon: MdSpaceDashboard,
    },
    {
      text: "Reporting",
      link: Path.REPORTING,
      id: useId(),
      icon: HiDocumentReport,
    },
    {
      text: "Transactions",
      link: Path.TRANSACTIONS,
      id: useId(),
      icon: AiFillNotification,
    },
    {
      text: "Merchant Payins",
      link: Path.MERCHANT_TRANSACTIONS,
      id: useId(),
      icon: MdSettings,
    },
    {
      text: "Merchant Payouts",
      link: Path.PAYOUTS,
      id: useId(),
      icon: BiSend,
    },
    {
      text: "Merchant Balances",
      link: Path.MERCHANT_PAYOUTS,
      id: useId(),
      icon: BiMoney,
    },
    {
      text: "Ticketing",
      link: Path.TICKETING,
      id: useId(),
      icon: RiInboxArchiveFill,
    },
    {
      text: "Archived",
      link: Path.ARCHIVED,
      id: useId(),
      icon: RiInboxArchiveFill,
    },
    {
      text: "Users",
      link: Path.USERS,
      id: useId(),
      icon: BiUser,
    },
    {
      text: "KYC Approval",
      link: Path.USERS_KYC,
      id: useId(),
      icon: BiUser,
    },
    {
      text: "Inactive Accounts",
      link: Path.IN_USERS,
      id: useId(),
      icon: BiUser,
    },
    {
      text: "Virtual Banks",
      link: Path.VIRTUAL_BANKS,
      id: useId(),
      icon: AiFillBank,
    },
    {
      text: "Whatsapp",
      link: Path.WHATSAPP,
      id: useId(),
      icon: BsFillChatSquareDotsFill,
    },
    {
      text: "Settings",
      link: Path.SETTINGS,
      id: useId(),
      icon: MdSettings,
    },
    {
      text: "Report Dashboard",
      link: Path.REPORT_DASHBOARD,
      id: useId(),
      icon: MdSettings,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const handleLogout = () => {
    dispatch!({ type: ActionTypes.logout });
    sessionStorage.removeItem("POWERFULL_USER");
    clearAccessToken();
    navigate(Path.LOGIN);
  };

  return (
    <Box as="aside" className="sideNav">
      <Box className="sideNav__container">
        <Box className="sideNav__imgDiv">
          <img src="/images/brand.png" alt="Logo" />
        </Box>
        <Box className="sideNav__links">
          {links.map((item) => (
            <SideNavLink
              key={item.id}
              isActive={getActiveLink(location.pathname, item.text)}
              {...item}
            />
          ))}
          <Button className="sideNav__logout" onClick={handleLogout}>
            <BiLogOut />
            <span className="sideNav__logoutText">Logout</span>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SideNav;
